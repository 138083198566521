import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Locale } from '../../enums/Locale';
import { Section } from '../../enums/Section';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import i18n from '../../plugins/i18n';
import { open } from '../../store/contact-form/contactFormSlice';
import { Colors } from '../../utils/Colors';
import NavLink from './NavLink';

interface Props {
    closeDrawer?: () => void;
}

function BrowserNav({ closeDrawer }: Props) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('navbar');
    const [lang, setLang] = useState(i18n.language);
    const { isMobile } = useWindowDimensions();

    const language = () => {
        if (lang === Locale.EN) {
            return Locale.FR;
        }

        return Locale.FR;
    };

    function onPress() {
        if (closeDrawer) {
            closeDrawer();
        }
    }

    async function changeLocale() {
        setLang(language());
        moment.locale(language());
        await i18n.changeLanguage(language());
    }

    return (
        <>
            <NavLink
                title={t('home')}
                to={Section.INTRO}
                onClick={onPress}
                offset={-100}
            />
            <NavLink
                title={t('services')}
                to={Section.SERVICES}
                onClick={onPress}
            />
            <NavLink
                title={t('featured_clients')}
                to={Section.FEATURED_CLIENTS}
                onClick={onPress}
            />
            <NavLink
                title={t('about_us')}
                to={Section.TEAM}
                onClick={onPress}
            />

            <QuoteContainer
                onClick={() => {
                    dispatch(open());
                    onPress();
                }}
            >
                <Text>{t('quote')}</Text>
            </QuoteContainer>

            {!isMobile && <Separator />}
            <NavLink
                title={language().toUpperCase()}
                to={Section.EMPTY}
                onClick={changeLocale}
            />
        </>
    );
}

const Separator = styled.div`
    border: 1px solid ${Colors.primary};
    height: 20px;
`;

const QuoteContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4px 20px;
    border-radius: 10px;

    background-color: ${Colors.primary};

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
`;

const Text = styled.div`
    color: ${Colors.white};
`;

export default BrowserNav;
